<template>
  <div class="abtest-data-container">
    <a-page-header @back="goback">
      <span slot="title">返回</span>
      <span slot="title" class="line"></span>
      <span slot="title">聚合管理</span>
    </a-page-header>
    <div class="abtest_title">
      <div class="title_item">
        <div class="item_box">
          <span class="title">应用</span>
          <div class="name">
            <img :src="testInfo.appIcon ? resourceUrl + testInfo.appIcon : '/images/app-none.png'" alt="" />
            <div style="display: flex; flex-direction: column">
              <span>
                <IosSvg v-if="testInfo.os === '0'" style="width: 14px; height: 14px" />
                <AndroidSvg v-if="testInfo.os === '1'" style="width: 14px; height: 14px" />
                {{ testInfo.appName || '--' }}
              </span>
              <span>ID：{{ testInfo.appId || '--' }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="title_item">
        <div class="item_box">
          <span class="title">广告位</span>
          <span class="name">{{ testInfo.placeName || '--' }}</span>
        </div>
      </div>
      <div class="title_item">
        <div class="item_box">
          <span class="title">流量分组</span>
          <span class="name">{{ testInfo.groupName || '--' }}</span>
        </div>
      </div>
      <div class="title_item">
        <div class="item_box">
          <span class="title">A/B测试</span>
          <span class="name">{{ testInfo.testName || '--' }}</span>
        </div>
      </div>
      <div class="title_item">
        <div class="item_box">
          <span class="title">生效时间</span>
          <span class="name">{{ testInfo.takeEffectTime || '--' }}</span>
        </div>
      </div>
    </div>
    <div class="abtest_table_wrapper">
      <div class="control">
        <a-range-picker
          style="margin-right: 20px; width: 250px"
          format="YYYY-MM-DD"
          :placeholder="['Start', 'End']"
          @change="changeDate"
          :value="rangePickerDate"
          :allowClear="false"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :disabledDate="disabledDate"
        >
          <a-icon slot="suffixIcon" type="calendar" />
        </a-range-picker>
        <div class="report">
          <a-button type="link" @click="addAbtestVisible = true"><a-icon type="form" />修改流量分配</a-button>
          <a-button type="link" @click="closeAbtestVisible = true"><guanbiSvg class="svg-icon" />结束测试</a-button>
          <a-button type="link" @click="exportReport" :loading="downloading"><a-icon type="download" />导出 </a-button>
        </div>
      </div>
      <a-spin :spinning="isLoading">
        <a-table
          class="abtest_table"
          size="middle"
          :scroll="{ x: true }"
          :rowKey="(record, index) => index"
          :data-source="testInfo.abTestReportDataList"
          :pagination="false"
          bordered
        >
          <!-- 分组名 -->
          <a-table-column align="left" title="分组" dataIndex="testGroupName">
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <div class="number">{{ record.testGroupName }}</div>
              <span class="isContrast">
                <img :src="record.isContrast ? compare : experiment" alt="" />
                <span :class="[record.isContrast ? 'compare' : 'experiment']">{{
                  record.isContrast ? '对照组' : '实验组'
                }}</span>
              </span>
            </div>
          </a-table-column>
          <!-- 比例 -->
          <a-table-column align="left" title="比例" dataIndex="proportion" v-if="type === 0">
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <div>{{ record.proportion }}%</div>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
          <!-- 区间比例 -->
          <a-table-column align="left" title="区间比例" v-if="type === 1">
            <template slot-scope="text, record">
              <span v-if="record.testId !== 'default'"> {{ getProportion(record) }}% </span>
              <span v-else>0%</span>
            </template>
          </a-table-column>
          <!-- 预估收益 -->
          <a-table-column align="left" title="预估收益" dataIndex="income">
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <div>{{ numFormat(record.income, 3, '') }}</div>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
          <!-- 预估eCPM -->
          <a-table-column align="left" title="预估eCPM" dataIndex="estimatedRevenueEcpm">
            <div class="slot_dataIndex pointer" slot-scope="estimatedRevenueEcpm, record">
              <div>{{ numFormat(record.estimatedRevenueEcpm, 3, '') }}</div>
              <span
                v-if="record.isContrast === 0"
                :class="{
                  increase: record.estimatedRevenueEcpmContrast > 0,
                  decrease: record.estimatedRevenueEcpmContrast < 0
                }"
              >{{ getContrast(record.estimatedRevenueEcpmContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 流量请求 -->
          <a-table-column align="left" title="流量请求" dataIndex="request">
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <div>{{ numFormat(record.request, 3, '') }}</div>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
          <!-- 请求eCPM -->
          <a-table-column align="left" dataIndex="requestEcpm">
            <a-popover slot="title" placement="top">
              <template #content>
                <p style="width: 200px; margin-bottom: 0">计算公式：该A/B测试组的预估收益/该A/B测试组的流量请求*1000</p>
              </template>
              <div>请求eCPM</div>
            </a-popover>
            <div class="slot_dataIndex pointer" slot-scope="requestEcpm, record">
              <div>{{ numFormat(record.requestEcpm, 3, '') }}</div>
              <span
                v-if="record.isContrast === 0"
                :class="{ increase: record.requestEcpmContrast > 0, decrease: record.requestEcpmContrast < 0 }"
              >{{ getContrast(record.requestEcpmContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 流量填充率 -->
          <a-table-column align="left" title="流量填充率" dataIndex="requestFilledRate">
            <div class="slot_dataIndex pointer" slot-scope="requestEcpm, record">
              <div>{{ record.requestFilledRate }}%</div>
              <span
                v-if="record.isContrast === 0"
                :class="{
                  increase: record.requestFilledRateContrast > 0,
                  decrease: record.requestFilledRateContrast < 0
                }"
              >{{ getContrast(record.requestFilledRateContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 填充耗时 -->
          <a-table-column align="left" title="填充耗时" dataIndex="cacheTime">
            <div class="slot_dataIndex pointer" slot-scope="requestEcpm, record">
              <div>{{ record.cacheTime }}s</div>
              <span
                v-if="record.isContrast === 0"
                :class="{ increase: record.cacheTimeContrast > 0, decrease: record.cacheTimeContrast < 0 }"
              >{{ getContrast(record.cacheTimeContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 展示 -->
          <a-table-column align="left" title="展示" dataIndex="impress">
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <template>{{ numFormat(record.impress, 3, '') }}</template>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
          <!-- 展示率 -->
          <a-table-column align="left" title="展示率" dataIndex="impressRate">
            <div class="slot_dataIndex pointer" slot-scope="requestEcpm, record">
              <div>{{ record.impressRate }}%</div>
              <span
                v-if="record.isContrast === 0"
                :class="{ increase: record.impressRateContrast > 0, decrease: record.impressRateContrast < 0 }"
              >{{ getContrast(record.impressRateContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 展请率 -->
          <a-table-column align="left" dataIndex="impressRequestRate">
            <a-popover slot="title" placement="top">
              <template #content>
                <p style="width: 200px; margin-bottom: 0">
                  {{ `${title}统计到的该A/B测试组下的展示与流量请求的比例` }}
                </p>
              </template>
              <div>展请率</div>
            </a-popover>
            <div class="slot_dataIndex pointer" slot-scope="requestEcpm, record">
              <div>{{ record.impressRequestRate }}%</div>
              <span
                v-if="record.isContrast === 0"
                :class="{
                  increase: record.impressRequestRateContrast > 0,
                  decrease: record.impressRequestRateContrast < 0
                }"
              >{{ getContrast(record.impressRequestRateContrast) }}%</span
              >
            </div>
          </a-table-column>
          <!-- 点击率 -->
          <a-table-column title="点击率" align="left" dataIndex="clickRate">
            <!-- <a-popover slot="title" placement="top">
              <template #content>
                <p style="width: 200px; margin-bottom: 0">
                  {{ `${title}统计到的该A/B测试组下的展示与流量请求的比例` }}
                </p>
              </template>
              <div>点击率</div>
            </a-popover> -->
            <div class="slot_dataIndex pointer" slot-scope="clickRate, record">
              <div>{{ record.clickRate }}%</div>
            </div>
          </a-table-column>
          <!-- DAU -->
          <a-table-column align="left" dataIndex="dau">
            <a-popover slot="title" placement="top">
              <template #content>
                <p style="width: 200px; margin-bottom: 0">
                  请求过该A/B测试的独立用户数，在时间段内DAU为每日DAU累加，不去重
                </p>
              </template>
              <div>DAU</div>
            </a-popover>
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <template>{{ numFormat(record.dau, 3, '') }}</template>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
          <!-- DEU -->
          <a-table-column align="left" dataIndex="deu">
            <a-popover slot="title" placement="top">
              <template #content>
                <p style="width: 200px; margin-bottom: 0">
                  每天观看过本A/B测试广告的独立用户数，在时间段内DEU为每日DEU累加，不去重
                </p>
              </template>
              <div>DEU</div>
            </a-popover>
            <div class="slot_dataIndex pointer" slot-scope="text, record">
              <template>{{ numFormat(record.deu, 3, '') }}</template>
              <p v-if="!record.isContrast"></p>
            </div>
          </a-table-column>
        </a-table>
      </a-spin>
    </div>
    <AbtestChart />
    <AbtestModal
      v-if="addAbtestVisible"
      :visible="addAbtestVisible"
      @modalCancel="addAbtestVisible = false"
      :abtestInfo="groupInfo"
      :isAdd="false"
      :query="query"
      :appName="testInfo.appName"
      :placeName="testInfo.placeName"
      :groupName="testInfo.groupName"
      @changeTestInfo="getTableData"
    />
    <CloseAbtestModal
      v-on="$listeners"
      v-bind="$attrs"
      v-if="closeAbtestVisible"
      :visible="closeAbtestVisible"
      @modalCancel="closeAbtestVisible = false"
      :appName="testInfo.appName"
      :placeName="testInfo.placeName"
      :groupName="testInfo.groupName"
      :groupInfo="groupInfo"
      :query="query"
      @handleEndAbtest="goback"
    />
  </div>
</template>

<script>
import AbtestModal from '../Config/components/abtestModal'
import CloseAbtestModal from '../Config/components/closeAbtestModal'
import AbtestChart from './abtestChart.vue'
import { getAbTestReport, getGroupIdListByPlace } from '@/api/aggregate'
import mixDate from '@/mixins/initDate'
import request from '@/utils/request'
import { showDay, numFormat } from '@/utils/dealNumber'
import excel from '@/utils/excel'
import { getDuration } from '@/utils/duration'
import { mapState } from 'vuex'
import guanbiSvg from '@/assets/icons/guanbi.svg?inline'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
const compare = require('@/assets/images/compare.png')
const experiment = require('@/assets/images/experiment.png')
export default {
  name: 'Abtestdata',
  mixins: [mixDate],
  components: { AbtestModal, AbtestChart, CloseAbtestModal, guanbiSvg, IosSvg, AndroidSvg },
  data () {
    return {
      query: {
        appId: '',
        placeId: '',
        groupId: '',
        endDate: '',
        startDate: ''
      },
      testInfo: {
        appName: '--',
        placeName: '--',
        groupName: '--',
        takeEffectTime: '--',
        testName: '--',
        abTestReportDataList: []
      },
      groupInfo: {},
      type: 0,
      addAbtestVisible: false,
      isLoading: false,
      downloading: false,
      closeAbtestVisible: false,
      rangePickerDate: null,
      compare,
      experiment
    }
  },
  watch: {
    testInfo: {
      handler (val) {
        this.groupInfo.appName = val.appName
      },
      immediate: true
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.query.endDate = endDate
    this.query.startDate = startDate
    this.rangePickerDate = [start, end]
  },
  computed: {
    ...mapState({
      // 平台名
      title: (state) => state.autoweb.title,
      resourceUrl: (state) => state.autoweb.resourceUrl
    })
  },
  mounted () {
    this.query.appId = this.$route.query.appId
    this.query.placeId = this.$route.query.placeId
    this.query.groupId = this.$route.query.groupId
    this.getGroupInfo()
    this.getTableData()
  },
  methods: {
    numFormat,
    getContrast (data) {
      let datas = 0
      datas = data < 0 ? '↓ ' + String(data).replace(/-/g, '') : data > 0 ? '↑ ' + data : data
      return datas
    },
    // 导出表格接口函数
    downloadReport (query) {
      return request.post('abtest/downAbTestReport', query, {
        responseType: 'blob'
      })
    },
    // 导出报表
    async exportReport () {
      this.downLoading = true
      const { appId, placeId, groupId = '', startDate = '', endDate = '' } = this.query || {}
      try {
        const res = await this.downloadReport({
          appId,
          placeId,
          groupId,
          startDate,
          endDate
        })
        await excel(res, `AB测试报表-${showDay(new Date())}.xls`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
    },
    goback () {
      this.$router.push({
        name: 'config',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId
        }
      })
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    getProportion (row) {
      let proportion = 0
      row.intervalList = !row.intervalList ? [] : row.intervalList
      row.intervalList.forEach((item) => {
        const p = item[1] - item[0] + 1
        proportion += p
      })
      return proportion
    },
    changeDate (date, dateString) {
      this.rangePickerDate = date
      this.query.endDate = dateString[1]
      this.query.startDate = dateString[0]
      this.getTableData()
    },
    async getTableData () {
      this.isLoading = true
      const resp = await getAbTestReport(this.query)
      if (resp.code === 200) {
        this.testInfo = {
          ...resp.data
        }
        this.isLoading = false
      }
    },
    async getGroupInfo () {
      const resp = await getGroupIdListByPlace(this.query.placeId)
      if (resp.code === 200) {
        this.groupInfo = resp.data.groups.find((item) => item.id === this.query.groupId)
        this.type = this.groupInfo.abTests[0].type || 0
      }
    }
  }
}
</script>

<style lang="less" scoped>
.abtest-data-container {
  margin: -55px 0 10px;
  ::v-deep {
    .ant-page-header {
      background: #fff;
      border-radius: 5px;
      padding: 7px 24px;
      .ant-page-header-back,
      .ant-page-header-heading-title {
        font-size: 14px;
      }
      .line {
        margin: 0 10px;
        background-color: gray;
        width: 2px;
        height: 12px;
        display: inline-block;
      }
    }
  }
  .abtest_title {
    background-color: #fff;
    margin: 10px 10px 0;
    position: relative;
    display: flex;
    border-radius: 5px;
    padding: 15px 10px;
    .title_item {
      width: 20%;
      min-width: 200px;
      display: flex;
      justify-content: flex-start;
      padding-left: 15px;
      .item_box {
        .title {
          font-size: 16px;
          color: #000;
          font-weight: 600;
        }
        .name {
          font-size: 14px;
          display: flex;
          margin-top: 10px;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 10px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .abtest_table_wrapper {
    border-radius: 5px;
    margin: 10px 10px 0;
    background: #fff;
    padding: 10px 20px;
    .control {
      padding: 10px;
      .report {
        float: right;
        .ant-btn {
          padding: 0;
          margin-left: 15px;
        }
        .svg-icon {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
    }
    .abtest_table {
      padding: 10px 10px 0;
      .slot_dataIndex {
        display: flex;
        flex-direction: column;
        i {
          font-size: 12px;
        }
        p {
          height: 21px;
          margin-bottom: 0;
        }
        .increase {
          color: #e34949;
        }
        .decrease {
          color: #00943e;
        }
        .isContrast {
          display: flex;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
          .compare {
            color: @primary-color;
          }
          .experiment {
            color: #5c1cdc;
          }
        }
      }
    }
  }
  ::v-deep {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-bottom: none;
    }
  }
}
</style>
